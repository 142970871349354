import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MembershipAssociateVm } from '../share/membership-associate-form';
import { BillingFormVm } from '../share/billing-form';
import { MembershipAssociateAddForm } from '@aaa/emember/store-membership-associate-add';

@Injectable({ providedIn: 'root' })
export class MembershipAssociateAddVm {
  fb = inject(FormBuilder);
  membershipAssociateVm = inject(MembershipAssociateVm);
  billingFormVm = inject(BillingFormVm);
  formGroup = this.create();

  create() {
    const formGroup = this.fb.group<MembershipAssociateAddForm>({
      associates: this.membershipAssociateVm.create(),
      billing: this.billingFormVm.create(),
      promoCode: new FormControl('', { nonNullable: true }),
    });

    return formGroup;
  }
}
