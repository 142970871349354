import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MembershipAssociateAddVm } from '../../modules/membership-associate-add/membership-associate-add.vm';
import { concat, filter, first, of, withLatestFrom } from 'rxjs';
import { bufferCount, map, switchMap, tap } from 'rxjs/operators';
import { PaymentActions } from '../payment/payment.actions';
import { MembershipAssociateAddActions } from './membership-associate-add.actions';
import { getMembershipAssociateFormActiveStep } from './membership-associate-add.selectors';
import { updateTreeValidity } from '../../modules/share/form.utils';
import { getMembershipNumber, MembershipActions } from '../membership';
import { MembershipAssociateAddStep } from './membership-associate-add.models';
import { RequestErrorType } from '../generic-errors';

@Injectable({ providedIn: 'root' })
export class MembershipAssociateAddEffects {
  store = inject(Store);
  actions$ = inject(Actions);
  membershipAssociateAddVm = inject(MembershipAssociateAddVm);

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.validateFormStep),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getMembershipAssociateFormActiveStep)))),
      switchMap(([{ validateStep, formValues }, currentActiveStep]) => {
        const validateAssociate = currentActiveStep === MembershipAssociateAddStep.ASSOCIATE;
        const validatePayment =
          currentActiveStep === MembershipAssociateAddStep.PAYMENT &&
          validateStep !== MembershipAssociateAddStep.ASSOCIATE;

        if (validateAssociate) {
          const validateAssociate$ = of(null).pipe(
            tap(() => this.store.dispatch(MembershipAssociateAddActions.validateAssociate())),
            switchMap(() =>
              this.actions$.pipe(ofType(MembershipAssociateAddActions.validateAssociateSucceeded), first()),
            ),
          );
          const validateRecost$ = of(null).pipe(
            tap(() => this.store.dispatch(MembershipAssociateAddActions.recostValidate({ formValues }))),
            switchMap(() => this.actions$.pipe(ofType(MembershipAssociateAddActions.recostValidateSucceeded), first())),
          );
          const validators = [validateAssociate$, validateRecost$];

          // wait unit all passed
          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() =>
              MembershipAssociateAddActions.setFormActiveStep({ activeStep: MembershipAssociateAddStep.PAYMENT }),
            ),
          );
        }

        if (validatePayment) {
          const validateBilling$ = of(null).pipe(
              tap(() => this.store.dispatch(MembershipAssociateAddActions.validateBilling())),
              switchMap(() =>
                this.actions$.pipe(ofType(MembershipAssociateAddActions.validateBillingSucceeded), first()),
              ),
            ),
            validatePayment$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.validate())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
            ),
            validateGenerateToken$ = of(null).pipe(
              tap(() => this.store.dispatch(PaymentActions.generateToken())),
              switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
            ),
            executePay$ = of(null).pipe(
              tap(() => this.store.dispatch(MembershipAssociateAddActions.pay({ formValues }))),
              switchMap(() => this.actions$.pipe(ofType(MembershipAssociateAddActions.paySucceeded), first())),
            ),
            validators = [validateBilling$, validatePayment$, validateGenerateToken$, executePay$];

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() =>
              MembershipAssociateAddActions.setFormActiveStep({ activeStep: MembershipAssociateAddStep.CONFIRMATION }),
            ),
          );
        }

        return of(MembershipAssociateAddActions.setFormActiveStep({ activeStep: validateStep }));
      }),
    ),
  );

  validateStepAssociate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.validateAssociate),
      map(() => {
        const isInValid = this.membershipAssociateAddVm.formGroup.controls.associates.status !== 'VALID';

        if (isInValid) {
          updateTreeValidity(this.membershipAssociateAddVm.formGroup.controls.associates);

          return MembershipAssociateAddActions.validateAssociateFailed();
        }

        return MembershipAssociateAddActions.validateAssociateSucceeded();
      }),
    ),
  );

  validateBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.validateBilling),
      map(() => {
        const isInValid = this.membershipAssociateAddVm.formGroup.controls.billing.status !== 'VALID';

        if (isInValid) {
          updateTreeValidity(this.membershipAssociateAddVm.formGroup.controls.billing);

          return MembershipAssociateAddActions.validateBillingFailed();
        }

        return MembershipAssociateAddActions.validateBillingSucceeded();
      }),
    ),
  );

  retryRecostWhenPromoCodeInvalid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.recostValidateFailed),
      filter(({ error }) => [RequestErrorType.MembershipAddAssociateInvalidPromoCode].includes(error.type)),
      // tap(() => {
      //   this.joinFormVm.promoCode.setValidators(setFormControlError({ invalidPromoCode: true }));
      //   updateTreeValidity(this.joinFormVm.promoCode);
      // }),
      map(() => {
        const formValues = structuredClone(this.membershipAssociateAddVm.formGroup.value);

        if (formValues.promoCode) {
          formValues.promoCode = '';
        }

        return MembershipAssociateAddActions.retryRecostValidate({ formValues });
      }),
    ),
  );

  loadMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembershipAssociateAddActions.paySucceeded),
      withLatestFrom(this.store.select(getMembershipNumber)),
      map(([{ accountDetails }, membershipNumber]) => {
        if (accountDetails) {
          return MembershipActions.updateAccountDetails({ accountDetails });
        }

        return MembershipActions.load({ membershipNumber: String(membershipNumber) });
      }),
    ),
  );
}
